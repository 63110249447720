import { Box, IconButton, InputBase } from "@mui/material";
import { useNavigate, useSearchParams } from "react-router-dom";
import React, { useCallback, useEffect, useState } from "react";
import { exportAllUsers, useFetchUsers } from "../../services/apis/users";
import { debounce } from "../../services/helpers";
import { handlePageChange } from "../../services/utils/filters";
import Header from "../../components/Header";
import { saveAs } from "file-saver";
import { ItemsColumns } from "../../components/common/Columns";
import AutoRefresh from "../../components/common/AutoRefresh";
import { useGlobalSocket } from "../../useGlobalSocket";
import NewTable from "../../components/common/NewTable";
import UserFilterDrawer from "./UserFilterDrawer";
import useAutoRefreshUserInformationStore from "../../zustand/AutoRefreshStore";
const Users = () => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters

  const handleRowClick = (row) => {
    navigate(`/users/${row.id}`);
  };
  const [queryParameters, setQueryParameters] = useState({
    date_from: searchParams.get("date_from") || null,
    date_to: searchParams.get("date_to") || null,
    kyc_status: searchParams.get("kyc_status")?.split(",") || [],
    is_online: searchParams.get("is_online") || "all",
    orderBy: searchParams.get("orderBy") || null,
    sort: searchParams.get("sort") || null,
    page: Number(searchParams.get("page")) || 0,
    limit: Number(searchParams.get("limit")) || 10,
    query: "",
    user_groups: searchParams.get("user_groups")?.split(",") || [],
  });
  // const [queryParameters, setQueryParameters] = useState({
  //   date_from: null,
  //   date_to: null,
  //   kyc_status: "",
  //   is_online: "all",
  //   orderBy: null,
  //   sort: null,
  //   page: Number(searchParams.get("page")) || 0,
  //   limit: 10,
  //   query: "",
  //   user_groups: [],
  // });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { data, isFetching, refetch } = useFetchUsers(queryParameters);
  const { toggleListen, setToggleListen } =
    useAutoRefreshUserInformationStore();
  const { addEventListener, removeEventListener } = useGlobalSocket();

  useEffect(() => {
    if (toggleListen) {
      refetch();
      addEventListener("users-update", ({ user_id }) => {
        refetch();
      });
      return () => {
        removeEventListener("users-update", () => {});
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener, toggleListen]);

  const newUsersColumns = ItemsColumns([
    {
      field: "firstNames",
      headerName: "Username",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        const firstName = params?.row?.firstName || "";
        const lastName = params?.row?.lastName || "";
        return <p>{`${firstName} ${lastName}`.trim()}</p>;
      },
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 2,
      specialRender: "customRender",
      renderFunction: (params) => {
        return <p>0{params?.row?.phone}</p>;
      },
    },
    { field: "email", flex: 2 },
    {
      field: "status",
      headerName: "Document Status",
      documentStatus: true,
      flex: 2,
    },
    {
      field: "is_online",
      headerName: "Online Status",
      isOnline: true,
      flex: 2,
    },

    { field: "created", headerName: "Join Date", date: true, flex: 2 },
  ]);

  // Debounced handler
  const handleSearch = useCallback(
    debounce((value) => {
      setQueryParameters((prev) => ({
        ...prev,
        query: value,
      }));
    }, 500),
    []
  );
  const downloadUsers = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const response = await exportAllUsers(queryParameters);

      // Assuming the response contains the CSV file as Base64
      const base64Content = response?.data?.data;

      if (!base64Content) {
        throw new Error("No data received for the report");
      }

      // Convert Base64 to a CSV file
      const csvContent = atob(base64Content); // Decode Base64
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      // Use FileSaver to download the file
      saveAs(blob, "users_report.csv");
    } catch (err) {
      console.error("Error downloading the report:", err.message);
      setError("Failed to download the report. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    const params = new URLSearchParams();

    Object.entries(queryParameters).forEach(([key, value]) => {
      if (value !== null && value !== "" && value !== "all") {
        if (Array.isArray(value) && value.length > 0) {
          params.append(key, value.join(","));
        } else {
          params.append(key, value);
        }
      }
    });

    setSearchParams(params);
  }, [queryParameters, setSearchParams]);

  return (
    <Box>
      <Header title="User Information" marginL={"25px"} />
      <div
        className="bg-white mx-4 rounded-xl"
        style={{
          boxShadow: "0px 1px 3px 0px #00000005, 0px 1px 6px 0px #00000012",
        }}
      >
        <AutoRefresh
          setToggleListen={setToggleListen}
          toggleListen={toggleListen}
        />
        <div className=" m-8 md:flex items-center justify-between">
          <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 w-[95%] md:w-3/5">
            <IconButton type="button" aria-label="search">
              <img alt="search-normal" src={`../../assets/search-normal.png`} />
            </IconButton>
            <InputBase
              onChange={(event) => {
                handleSearch(event.target.value);
              }}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search using Phone number , email or National ID.... "
            />
            <UserFilterDrawer
              setSearchParams={setSearchParams}
              data={queryParameters}
              setData={setQueryParameters}
            />
          </div>
          <button
            disabled={isLoading}
            onClick={() => downloadUsers()}
            className="rounded-xl bg-[#917244] text-white px-12 py-3 text-sm mt-4 w-[95%] md:w-fit hidden md:block   md:m-0 mr-10"
          >
            {isLoading ? "Downloading..." : "Download Report"}
          </button>
        </div>
        <div className="">
          <NewTable
            setLimit={setQueryParameters}
            limit={queryParameters.limit}
            centerHeader
            columns={newUsersColumns}
            isLoading={isFetching}
            data={data?.data?.data?.result}
            totalItems={data?.data?.data?.totalItems}
            totalPages={data?.data?.data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(
                newPage,
                queryParameters,
                setQueryParameters,
                setSearchParams
              )
            }
            handleRowClick={handleRowClick}
            currentPage={queryParameters}
          />
        </div>
        <div className="flex items-center justify-center m-2">
          <button
            onClick={() => downloadUsers()}
            className="rounded-lg bg-[#917244] text-white px-4 py-2 text-sm mt-4 w-[90%] md:hidden mx-auto  md:m-0"
          >
            Download Report
          </button>
        </div>
      </div>
    </Box>
  );
};

export default Users;
