import { Box, Tooltip } from "@mui/material";

const TooltipComponent = ({ title }) => {
  return (
    <Tooltip title={title} arrow placement="top">
      <Box sx={{ width: 24, height: 24 }}>
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          cursor={"pointer"}
        >
          <path
            d="M9 17.0625C4.5525 17.0625 0.9375 13.4475 0.9375 9C0.9375 4.5525 4.5525 0.9375 9 0.9375C13.4475 0.9375 17.0625 4.5525 17.0625 9C17.0625 13.4475 13.4475 17.0625 9 17.0625ZM9 2.0625C5.175 2.0625 2.0625 5.175 2.0625 9C2.0625 12.825 5.175 15.9375 9 15.9375C12.825 15.9375 15.9375 12.825 15.9375 9C15.9375 5.175 12.825 2.0625 9 2.0625Z"
            fill="#B3B3B3"
          />
          <path
            d="M9 10.3125C8.6925 10.3125 8.4375 10.0575 8.4375 9.75V6C8.4375 5.6925 8.6925 5.4375 9 5.4375C9.3075 5.4375 9.5625 5.6925 9.5625 6V9.75C9.5625 10.0575 9.3075 10.3125 9 10.3125Z"
            fill="#B3B3B3"
          />
          <path
            d="M9 12.7502C8.9025 12.7502 8.805 12.7277 8.715 12.6902C8.625 12.6527 8.5425 12.6002 8.4675 12.5327C8.4 12.4577 8.3475 12.3827 8.31 12.2852C8.2725 12.1952 8.25 12.0977 8.25 12.0002C8.25 11.9027 8.2725 11.8052 8.31 11.7152C8.3475 11.6252 8.4 11.5427 8.4675 11.4677C8.5425 11.4002 8.625 11.3477 8.715 11.3102C8.895 11.2352 9.105 11.2352 9.285 11.3102C9.375 11.3477 9.4575 11.4002 9.5325 11.4677C9.6 11.5427 9.6525 11.6252 9.69 11.7152C9.7275 11.8052 9.75 11.9027 9.75 12.0002C9.75 12.0977 9.7275 12.1952 9.69 12.2852C9.6525 12.3827 9.6 12.4577 9.5325 12.5327C9.4575 12.6002 9.375 12.6527 9.285 12.6902C9.195 12.7277 9.0975 12.7502 9 12.7502Z"
            fill="#B3B3B3"
          />
        </svg>
      </Box>
    </Tooltip>
  );
};

export default TooltipComponent;
