import React from "react";
import useUserFilterData from "../../../zustand/useUserFilterData";

const ProviderType = () => {
  const { providerType, setProviderType } = useUserFilterData();
  const filterList = [
    { id: "All", name: "All", value: 0 },
    { id: "SAM", name: "SAM", value: 1 },
    { id: "BTC", name: "BTC", value: 2 },
  ];

  const handleCheckboxChange = (e) => {
    const { value } = e.target;
    const parsedValue = Number(value); // Parse the value to a number
    setProviderType([parsedValue]); // Set only the selected value
  };

  return (
    <div className="mt-4">
      <p className="text-[#404040] font-medium font-ibmArabic text-lg mb-4">
        Provider
      </p>
      <div className="grid grid-cols-3 gap-4">
        {filterList.map((item) => (
          <div key={item.id} className="flex items-center gap-2">
            <input
              className="text-[#595959] size-4 cursor-pointer"
              type="checkbox"
              id={item.id}
              name="providerType"
              value={item.value}
              checked={providerType.includes(item.value)} // Check if this value is selected
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={item.id} // Use htmlFor for JSX
              className="text-[#595959] font-medium font-ibmArabic text-lg"
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProviderType;
