import React, { useState } from "react";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import arrowLeft from "../../svg/arrow-circle-left.svg";
import documentImg from "../../svg/document-download.svg";
import { useNavigate, useParams } from "react-router-dom";
import {
  DateTimePicker,
  LocalizationProvider,
  renderTimeViewClock,
} from "@mui/x-date-pickers";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import {
  Box,
  Button,
  InputBase,
  Paper,
  Skeleton,
  TextField,
} from "@mui/material";
import UserInfoField from "../user-profile/UserInfoField";
import { EditSvgWithFunc } from "../../components/common/SvgWithFunc";
import NewTable from "../../components/common/NewTable";
import { ItemsColumns } from "../../components/common/Columns";
import {
  useFetchVoucherById,
  useEditVoucher,
} from "../../services/apis/voucher";
import { formDate } from "../../services/helpers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { handlePageChange } from "../../services/utils/filters";

const voucherColumns = ItemsColumns([
  { field: "full_name", headerName: "Username" },
  { field: "phone", headerName: "Phone Number" },
  { field: "total", headerName: "Order Value" },
  { field: "discount", headerName: "Discount Value" },
  { field: "created", headerName: "Order Date", date: true },
]);
const VoucherDetails = () => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false); // Toggle edit mode
  const { id } = useParams();
  const [queryParameters, setQueryParameters] = useState({
    id: id,
    page: 0,
  });
  const { data: voucher, isLoading } = useFetchVoucherById(queryParameters);
  const { mutateAsync, isLoading: EditLoading } = useEditVoucher();
  const [editData, setEditData] = useState({
    user_limit_count: voucher?.user_limit_count,
    end_date: voucher?.end_Date,
    limit_count: voucher?.limit_count,
  });
  // Handle input change for both TextFields and DateTimePickers
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData({ ...editData, [name]: Number(value) });
  };

  // Handle DateTimePicker changes
  const handleDateChange = (name, date) => {
    setEditData({ ...editData, [name]: date });
  };
  // Handle Save - Submit the updated data
  const handleSave = async () => {
    const sentData = {
      user_limit_count: editData?.user_limit_count,
      end_date: editData?.end_date
        ? editData?.end_date.subtract(3, "hours").format("YYYY-MM-DDTHH:mm:ss")
        : dayjs(voucher?.end_date)
            .subtract(3, "hours")
            .format("YYYY-MM-DDTHH:mm:ss"),
      limit_count: editData?.limit_count,
    };
    await mutateAsync({ id, ...sentData }).then(() => {
      setEditMode(false); // Exit edit mode after saving
    });
    // You can add an API call here to save the data
  };

  // Handle Cancel - Discard changes and reset the temporary state
  const handleCancel = () => {
    setEditMode(false); // Exit edit mode
    setEditData({
      user_limit_count: voucher?.user_limit_count,
      end_date: voucher?.end_date,
      limit_count: voucher?.limit_count,
    });
  };

  return (
    <div>
      <DashboardMainHeader />
      <div className="flex items-center gap-2 ml-6">
        <img
          src={arrowLeft}
          alt=""
          className="cursor-pointer"
          onClick={() => {
            navigate(-1);
            // navigate("/voucher");
          }}
        />
        <Header title={"Voucher Details"} />
      </div>
      <div className="mx-6 mt-6 bg-white rounded-2xl p-6 border">
        <div
          className={`${voucher?.deleted_at || isLoading ? "hidden" : "flex"} 
          
          items-center justify-end mx-10 mb-6`}
        >
          <EditSvgWithFunc
            onClick={() => {
              setEditData({
                user_limit_count: voucher?.user_limit_count,
                end_date: voucher?.end_Date,
                limit_count: voucher?.limit_count,
              });
              setEditMode(true);
            }} // Toggle edit mode
            className={""}
          />
        </div>

        <div className="grid md:grid-cols-3">
          <UserInfoField
            title="Voucher Code"
            data={isLoading ? <Skeleton sx={{ width: 200 }} /> : voucher?.code}
          />
          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden" />

          <UserInfoField
            title="Type"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                voucher?.discount_type
              )
            }
          />
          <UserInfoField
            title="Cashback"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : voucher?.cashback ? (
                voucher?.cashback + "%"
              ) : (
                " "
              )
            }
          />
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
        {voucher?.discount_type === "percentage" ? (
          <div className="grid md:grid-cols-2">
            <UserInfoField
              title="Discount Value"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  voucher?.percentage
                )
              }
            />
            <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />
            <UserInfoField
              title="Max Discount Cap"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  voucher?.percentage_limit
                )
              }
            />
          </div>
        ) : (
          <UserInfoField
            title="Discount Value"
            data={isLoading ? <Skeleton sx={{ width: 200 }} /> : voucher?.value}
          />
        )}

        <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
        <div className={`grid md:grid-cols-3`}>
          <UserInfoField
            title="Minimum Order Value"
            data={
              isLoading ? <Skeleton sx={{ width: 200 }} /> : voucher?.min_order
            }
          />
          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden" />
          <UserInfoField
            className={"whitespace-nowrap"}
            title="Redemption Count"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : editMode ? (
                <TextField
                  name="limit_count"
                  value={editData?.limit_count}
                  onChange={handleInputChange}
                  fullWidth
                />
              ) : voucher?.limit_count === 0 ? (
                "Unlimited"
              ) : (
                voucher?.limit_count
              )
            }
          />

          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />
          <UserInfoField
            className={"whitespace-nowrap"}
            title="Total Used"
            data={
              isLoading ? <Skeleton sx={{ width: 200 }} /> : voucher?.totalUsed
            }
          />
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3  " />
        <div className="grid md:grid-cols-3">
          <UserInfoField
            title="Use limit per User"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : editMode ? (
                <TextField
                  name="user_limit_count"
                  value={editData?.user_limit_count}
                  onChange={handleInputChange}
                  fullWidth
                />
              ) : voucher?.user_limit_count === 1 ? (
                "One time"
              ) : (
                voucher?.user_limit_count
              )
            }
          />
          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />
          <UserInfoField
            title="Users"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : voucher?.user_ids === null &&
                voucher?.for_first_order === 0 ? (
                "All Users"
              ) : voucher?.for_first_order === 1 &&
                voucher?.user_ids === null ? (
                "First User"
              ) : (
                "Specific Users"
              )
            }
          />
          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />
          <UserInfoField
            title="Status"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                `
      ${
        dayjs(voucher?.start_date).isAfter(dayjs())
          ? "Inactive"
          : dayjs(voucher?.end_date).isBefore(dayjs())
          ? "Expired"
          : "Active"
      }  
      `
              )
            }
          />
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3    " />
        <div className="grid md:grid-cols-2">
          <UserInfoField
            title="Valid From"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                formDate(voucher?.start_date)
              )
            }
          />
          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden" />
          <UserInfoField
            title="Expires On"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : editMode ? (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    viewRenderers={{
                      hours: renderTimeViewClock,
                      minutes: renderTimeViewClock,
                      seconds: renderTimeViewClock,
                    }}
                    disablePast
                    value={dayjs(voucher?.end_date)}
                    onChange={(date) => handleDateChange("end_date", date)}
                    renderInput={(props) => <TextField {...props} fullWidth />}
                  />
                </LocalizationProvider>
              ) : (
                formDate(voucher?.end_date)
              )
            }
          />
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3" />
        <div
          className={`grid ${
            voucher?.deleted_at !== null ? " md:grid-cols-2" : " md:grid-cols-1"
          }`}
        >
          <UserInfoField
            title="Created on"
            data={
              isLoading ? (
                <Skeleton sx={{ width: 200 }} />
              ) : (
                formDate(voucher?.created)
              )
            }
          />
          <hr className="text-[#F5F5F5] border-t-[1px] my-3 md:hidden   " />
          {voucher?.deleted_at ? (
            <UserInfoField
              title="Deleted At"
              data={
                isLoading ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  formDate(voucher?.deleted_at)
                )
              }
            />
          ) : null}
        </div>
        <hr className="text-[#F5F5F5] border-t-[1px] my-3" />

        <div>
          <UserInfoField
            title="Created By"
            data={
              isLoading ? <Skeleton sx={{ width: 200 }} /> : "Created By User"
            }
          />
        </div>
        <div
          className={`flex items-center justify-end gap-4 mt-3 ${
            editMode ? "" : "hidden"
          }`}
        >
          <Button
            // disabled={submitting}
            onClick={handleSave}
            sx={{
              "&:hover": { backgroundColor: "#917244" },
              backgroundColor: "#917244",
              width: "200px",
              height: "46px",
              borderRadius: "12px",
              color: "#ffffff",
              fontSize: "14px",
              display: "flex",
              alignSelf: "flex-end",
            }}
            disabled={EditLoading}
          >
            Save
          </Button>
          <Button
            disabled={EditLoading}
            variant="outlined"
            autoCapitalize="false"
            sx={{
              width: "200px",
              borderRadius: "9px",
              height: "48px",
              color: "#917244",
              fontWeight: 600,
              borderColor: "#917244",
              "&:hover": {
                borderColor: "#917244",
              },
            }}
            onClick={handleCancel}
          >
            Cancel
          </Button>
        </div>
      </div>
      <div className="mt-4">
        <Header title={"Users"} marginL={4} />
        <Box
          sx={{
            display: "flex",
            width: "100%",
            gap: 2,
            mt: 1,
            p: 3,
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "space-between",
            flexWrap: "wrap",
          }}
        >
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: "60%",
              height: "48px",
              backgroundColor: "#FFF",
              boxShadow: "none",
              borderRadius: "12px",
            }}
            onSubmit={(event) => {
              event.preventDefault();
            }}
          >
            <InputBase
              // onChange={handleSearchInput}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search Email or Phone number....."
            />
          </Paper>
          <div className="flex items-center justify-center gap-5">
            <Button
              // onClick={() => downloadUsers()}
              sx={{
                width: "140px",
                borderRadius: "9px",
                background: "#fff",
                border: "1px solid #917244",
                "&:hover": {
                  background: "#fff",
                },
                color: "#917244",
                fontSize: "16px",
                textTransform: "none",
              }}
            >
              <img src={documentImg} alt="" className="mr-2" />
              Export
            </Button>
          </div>
        </Box>
      </div>
      <div className="mx-6 mb-6 rounded-2xl border">
        <NewTable
          columns={voucherColumns}
          isLoading={isLoading}
          data={voucher?.users?.result}
          totalItems={voucher?.users.totalItems}
          totalPages={voucher?.users.totalPages}
          handlePageChange={(newPage) =>
            handlePageChange(newPage, queryParameters, setQueryParameters)
          }
          currentPage={queryParameters}
        />
      </div>
      <ReusableSnackbar />
    </div>
  );
};

export default VoucherDetails;
