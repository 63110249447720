import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Typography,
  Paper,
  InputBase,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
} from "@mui/material";
import Header from "../../components/Header";
import { DataGridStyle } from "../../styles";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import { handlePageChange } from "../../services/utils/filters";
import {
  adminChangeStatus,
  useFetchTickets,
  useUserTickets,
} from "../../services/apis/tickets";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { saveAs } from "file-saver";
import CloseTicketDialog from "./CloseTicketDialog";
import ReusableSnackbar from "../../components/ReusableSnackbar";
import { useDispatch, useSelector } from "react-redux";
import { setData } from "../../store/slices/errorMessageSlice";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import { useGlobalSocket } from "../../useGlobalSocket";
import { addItem } from "../../store/slices/ticketSlice";
import AutoRefresh from "../../components/common/AutoRefresh";
import NewTable from "../../components/common/NewTable";
import useAutoRefreshTicketsStore from "../../zustand/AutoRefreshTicketsStore";
const ITEM_HEIGHTS = 36;
const ITEM_PADDING_TOPS = 8;
export const MenuItemProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHTS * 4.5 + ITEM_PADDING_TOPS,
      width: 80,
      backgroundColor: "#FAFAFA",
      border: 0,
    },
  },
};
const Tickets = () => {
  const tickets = useSelector((state) => state.tickets.data);
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters

  const dispatch = useDispatch();
  const [openBlockedAlert, setOpenBlockedAlert] = useState(false);
  const [selectedClosedStatusId, setSelectedClosedStatusId] = useState(null);
  const { id } = useParams();
  const navigate = useNavigate();
  const [queryParameters, setQueryParameters] = useState({
    page: Number(searchParams.get("page")) || 0,
  });
  const { data, isFetching, refetch } = useFetchTickets(queryParameters);
  const { toggleListen, setToggleListen } = useAutoRefreshTicketsStore();
  const { addEventListener, removeEventListener } = useGlobalSocket();
  useEffect(() => {
    if (toggleListen) {
      refetch(queryParameters);
      addEventListener("admin-ticketes", ({ data }) => {
        dispatch(addItem(data));
        refetch(queryParameters);
      });
    }
    return () => {
      removeEventListener("admin-ticketes", () => {});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addEventListener, toggleListen]);

  const {
    data: userTicket,
    isFetching: userIsFetchingData,
    refetch: userRefetchData,
  } = useUserTickets(id, queryParameters);

  const handleStatusChange = async (event, params) => {
    const selectedStatusId = event.target.value.id;
    if (selectedStatusId === 4) {
      setSelectedClosedStatusId(params.id);
      handleOpenBlockAlert();
    } else {
      try {
        const selectedId = event.target.value.id;
        const payload = {
          id: params.id,
          status: selectedId,
        };
        await adminChangeStatus(payload);
        refetch(queryParameters);
        if (id) {
          userRefetchData(id, queryParameters);
        }
      } catch (error) {
        if (error.response.status === 422) {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.validation.status,
            })
          );
        } else {
          dispatch(
            setData({
              openSnack: true,
              message: error.response.data.message,
            })
          );
        }
      }
    }
  };
  const Status = [
    { id: 1, name: "Open" },
    { id: 2, name: "Pending" },
    { id: 3, name: "Resolved" },
    { id: 4, name: "Close" },
  ];
  const ticketsColumns = ItemsColumns([
    {
      field: "id",
      headerName: "ID",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <div
            className={`flex items-center justify-center w-full  h-10 pl-1   border-l-2 my-auto
              
             ${
               tickets?.some((ticket) => ticket.id === params?.row?.id)
                 ? "border-l-[#dc3545]"
                 : "border-l-[#30d158]"
             }
              `}
          >
            <p>{params?.row?.id}</p>
          </div>
        );
      },
    },
    { field: "email", flex: 2 },
    {
      field: "full_name",
      headerName: "User name",
      specialRender: "customRender",
      renderFunction: (params) => {
        const firstName = params?.row?.firstName || "";
        const lastName = params?.row?.lastName || "";
        return <p>{`${firstName} ${lastName}`.trim()}</p>;
      },
    },
    { field: "created", headerName: "Creation date", flex: 2, date: true },
    { field: "responseDate", headerName: "Response Date", flex: 2, date: true },
    { field: "assignName", headerName: "Assigned to" },
    {
      field: "status",
      specialRender: "customRender",
      renderFunction: (params) => {
        if (params.row.status === 4) {
          return <Typography sx={{ color: "#30D158" }}>Closed</Typography>;
        } else {
          return (
            <Box
              sx={{
                width: "95px",
                backgroundColor: "#FFFFFF",
                borderRadius: "6px",
                borderBottom: "none",
              }}
            >
              <FormControl sx={{ width: "100%" }}>
                <Select
                  displayEmpty
                  onChange={(event) => handleStatusChange(event, params.row)}
                  value={null || ""}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            color:
                              params.row.status === 1
                                ? "#FF453A"
                                : params.row.status === 2
                                ? "#917244"
                                : params.row.status === 3
                                ? "#3284FF"
                                : "#30D158",
                          }}
                        >
                          {params.row.status === 1
                            ? "Open"
                            : params.row.status === 2
                            ? "Pending"
                            : params.row.status === 3
                            ? "Resolved"
                            : params.row.status === 4
                            ? "Closed"
                            : "Open"}
                        </Box>
                      );
                    }
                    return (
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <span
                          style={{
                            width: "8px",
                            height: "8px",
                            borderRadius: "50%",
                            backgroundColor: "#30D158",
                            marginRight: "8px",
                          }}
                        ></span>
                        {selected.name}
                      </Box>
                    );
                  }}
                  MenuProps={MenuItemProps}
                  inputProps={{ "aria-label": "Without label" }}
                  sx={{
                    boxShadow: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: 0 },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: 0,
                      },
                    "& .MuiSelect-select.MuiSelect-select": {
                      color: "#30D158",
                    },
                  }}
                >
                  {Status.map((item) => (
                    <MenuItem
                      key={item.id}
                      value={item}
                      sx={{
                        color:
                          item.id === 1
                            ? "#FF453A"
                            : item.id === 2
                            ? "#917244"
                            : item.id === 3
                            ? "#3284FF"
                            : "#30D158",
                      }}
                    >
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          );
        }
      },
    },
  ]);

  const downloadTickets = () => {
    const csvContent =
      ticketsColumns.map((column) => column.headerName).join(",") +
      "\n" +
      data?.result
        .map((ticket) => {
          return ticketsColumns
            .map((column) => {
              if (column.field === "status") {
                return ticket[column.field] === 1 ? "Open" : "Closed";
              }
              return ticket[column.field];
            })
            .join(",");
        })
        .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "tickets.csv");
  };

  const handleClick = (params) => {
    const ticket = params.row;
    let ticketDetails = null;
    if (id) {
      ticketDetails = userTicket?.result.find(
        (ticketitem) => ticketitem.id === ticket.id
      );
    } else {
      ticketDetails = data?.result.find(
        (ticketitem) => ticketitem.id === ticket.id
      );
    }
    navigate(`/ticketsDetails/${ticket.id}`, {
      state: { ticketsdetails: ticketDetails },
    });
  };
  const handleOpenBlockAlert = (e) => {
    setOpenBlockedAlert(true);
  };

  const handleCloseBlockAlert = () => {
    setOpenBlockedAlert(false);
  };

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <CloseTicketDialog
        getTickets={() => {
          refetch(queryParameters);
        }}
        selectedClosedStatusId={selectedClosedStatusId}
        openAlert={openBlockedAlert}
        handleCloseAlert={handleCloseBlockAlert}
        message={`Are you sure you want to Close this Ticket ?`}
        label={"Confirm"}
      />
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>
      <Header title="Tickets" marginL={"25px"} />
      <AutoRefresh
        setToggleListen={setToggleListen}
        toggleListen={toggleListen}
      />
      <SecondBox>
        <Box m="40px 0 0 0" height="auto" sx={DataGridStyle}>
          <Box>
            <Box
              sx={{
                display: "flex",
                width: "100%",
                gap: 2,
                mb: 2,
                p: 2,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Paper
                component="form"
                sx={{
                  p: "2px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "60%",
                  height: "42px",
                  backgroundColor: "#F5F5F5",
                  boxShadow: "none",
                  borderRadius: "12px",
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <IconButton
                  type="button"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <img
                    alt="search-normal"
                    src={`../../assets/search-normal.png`}
                  />
                </IconButton>
                <InputBase
                  // onChange={handleSearchInput}
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Filter by Status or Date ....."
                />
              </Paper>
              <Box>
                <Button
                  onClick={() => downloadTickets()}
                  sx={{
                    borderRadius: "9px",
                    background: "#917244",
                    "&:hover": {
                      background: "#917244",
                    },
                    height: "48px",
                    color: "#FFF",
                    fontSize: "16px",
                    textTransform: "none",
                  }}
                >
                  {"Download Report"}
                </Button>
              </Box>
            </Box>
          </Box>
          <NewTable
            columns={ticketsColumns}
            isLoading={id ? userIsFetchingData : isFetching}
            data={id ? userTicket?.result : data?.result}
            totalItems={
              id ? userTicket?.totalItems || 0 : data?.totalItems || 0
            }
            totalPages={id ? userTicket?.totalPages : data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(
                newPage,
                queryParameters,
                setQueryParameters,
                setSearchParams
              )
            }
            onCellClick={handleClick}
            currentPage={queryParameters}
          />
        </Box>
      </SecondBox>
      <ReusableSnackbar />
    </Box>
  );
};

export default Tickets;
