import React, { useEffect } from "react";
import {
  Typography,
  Box,
  Button,
  Divider,
  Switch,
  Snackbar,
  Alert,
  Skeleton,
  InputBase,
  IconButton,
} from "@mui/material";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import UserInfoField from "../../user-profile/UserInfoField";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import {
  fetchInventoryRequestSerials,
  useOrderHistory,
  useSubmitUpdateStatus,
} from "../../../services/apis/inventory";
import { formDate } from "../../../services/helpers";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { ItemsColumns } from "../../../components/common/Columns";
import { handlePageChange } from "../../../services/utils/filters";
import DeliveredOrderDialog from "./DeliveredOrderDialog";
import CancelOrderDialog from "./CancelOrderDialog";
import { EditSvgWithFunc } from "../../../components/common/SvgWithFunc";
import EditStorageLocationDialog from "./EditStorageLocationDialog";
import ChangeOrderStatus from "./ChangeOrderStatus";
import { useDispatch } from "react-redux";
import { setData } from "../../../store/slices/errorMessageSlice";
import NewTable from "../../../components/common/NewTable";
import useWindowSize from "../../../hooks/useWindowSize";
import OrderSerialsFilterDrawer from "./OrderSerialsFilterDrawer";

const OrderDetails = () => {
  const { width } = useWindowSize();
  const { id } = useParams();
  const [requestSerials, setRequestSerials] = useState([]);
  const navigate = useNavigate();
  const [totalITems, setTotalItems] = useState([]);
  const [totalPages, setTotalPages] = useState([]);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [open, setOpen] = useState(false);
  const [openDelivered, setOpenDelivered] = useState(false);
  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [openLocationDialog, setOpenLocationDialog] = useState(false);
  const [openChangeStatusDialog, setOpenChangeStatusDialog] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { mutate } = useSubmitUpdateStatus();
  const dispatch = useDispatch();
  const [queryParameters, setQueryParameters] = useState({
    page: 0,
    availability: null,
    sold_to: undefined,
  });
  const {
    data: orderHistoryDetails,
    isFetching,
    refetch,
  } = useOrderHistory(id);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };
  const getRequestSerials = async () => {
    setRequestSerials([]);
    try {
      const response = await fetchInventoryRequestSerials(queryParameters, id);
      const request = response?.data;
      setRequestSerials(request?.result);
      setTotalItems(request?.totalItems);
      setTotalPages(request?.totalPages);
    } catch (error) {
      console.log(error.response.data.message);
    }
  };
  const handleViewRequestDetails = (request) => {
    const { row, field } = request;
    if (field !== "action") {
      navigate(`/SerialDetails/${row.id}`, {
        state: { requestData: request.row },
      });
    }
  };
  const requestsColumns = ItemsColumns([
    { field: "serial", headerName: "Serial Number" },
    {
      field: "availability",
      headerName: "Item Stock Status",
      specialRender: "customRender",

      renderFunction: (params) => {
        return (
          <Typography
            sx={{
              color: params.row.availability === 1 ? "#28A745" : "#DC3545",
            }}
          >
            {params.row.availability === 1 ? "In Stock" : "Out of stock"}
          </Typography>
        );
      },
    },
    {
      field: "sold_to",
      headerName: "Item Sale Status",
      specialRender: "customRender",
      renderFunction: (params) => {
        return (
          <Typography
            sx={{ color: params.row.sold_to === null ? "#24CF80" : "#CF2424" }}
          >
            {params.row.sold_to === null ? "Available" : "Sold"}
          </Typography>
        );
      },
    },
    { field: "created", headerName: "Created At", date: true },
    {
      field: "action",
      headerName: "Change Stock Status",
      specialRender: "customRender",
      renderFunction: (params) => {
        const isChecked = params.row.availability === 1;
        const handleSwitchToggle = async (event) => {
          const newAvailability = event.target.checked ? 1 : 0;
          const id = params.row.id;

          if (params.row.availability === null) {
            setSelectedRowId(id);
            setOpenChangeStatusDialog(true);
            return;
          }

          setIsSubmitting(true);
          const payload = {
            availability: newAvailability,
          };
          mutate(
            {
              id: id,
              body: payload,
            },
            {
              onSuccess: () => {
                setOpen(true);
                setRequestSerials((prevSerials) =>
                  prevSerials?.map((serial) =>
                    serial.id === id
                      ? { ...serial, availability: newAvailability }
                      : serial
                  )
                );
                setIsSubmitting(false);
              },
              onError: (error) => {
                dispatch(
                  setData({
                    openSnack: true,
                    message: "An error occurred while submitting the order",
                  })
                );
                setIsSubmitting(false);
              },
            }
          );
        };

        return (
          <Switch
            checked={isChecked}
            onChange={handleSwitchToggle}
            disabled={isSubmitting}
            {...label}
            sx={{
              "& .MuiSwitch-thumb": {
                color: isChecked ? "#917244" : "#917244",
              },
              "& .MuiSwitch-track": {
                backgroundColor: isChecked ? "#0000001F" : "#0000001F",
              },
            }}
          />
        );
      },
    },
  ]);
  const handleSearchInput = (e) => {
    const { value } = e.target;
    setQueryParameters((prevParams) => ({
      ...prevParams,
      query: value.trim(),
    }));
  };

  useEffect(() => {
    getRequestSerials();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, queryParameters]);
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <DashboardMainHeader />
      <DeliveredOrderDialog
        refetch={refetch}
        requestData={orderHistoryDetails}
        getRequestSerials={getRequestSerials}
        open={openDelivered}
        onClose={() => {
          setOpenDelivered(false);
        }}
        label={"Confirm"}
      />
      <CancelOrderDialog
        refetch={refetch}
        requestData={orderHistoryDetails}
        open={openCancelDialog}
        getRequestSerials={getRequestSerials}
        onClose={() => {
          setOpenCancelDialog(false);
        }}
        label={"Yes , Cancel"}
      />
      <EditStorageLocationDialog
        refetch={refetch}
        requestData={orderHistoryDetails}
        open={openLocationDialog}
        getRequestSerials={getRequestSerials}
        onClose={() => {
          setOpenLocationDialog(false);
        }}
        label={"Save Changes"}
      />
      <ChangeOrderStatus
        refetch={refetch}
        requestData={requestSerials}
        selectedRowId={selectedRowId}
        open={openChangeStatusDialog}
        getRequestSerials={getRequestSerials}
        onClose={() => {
          setOpenChangeStatusDialog(false);
        }}
        label={"Confirm"}
      />
      <Box
        sx={{
          margin: "0 0 1% 2%",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <Box sx={{ cursor: "pointer" }}>
          <img
            src={arrowLeft}
            alt=""
            onClick={() => navigate(-1)}
            // onClick={() => {
            //   navigate("/requests");
            // }}
          />
        </Box>
        <Typography component="span" fontSize={"24px"} fontWeight={"400"}>
          Order Details
        </Typography>
      </Box>
      <Box
        m="20px"
        sx={{
          display: "flex",
          // width: "95%",
          flexDirection: "column",
          gap: 2,
          background: "#FFFFFF",
          alignItems: "center",
          borderRadius: "24px",
          height: "auto",
        }}
      >
        {orderHistoryDetails?.status === 1 && (
          <Box
            onClick={() => setOpenLocationDialog(true)}
            sx={{
              display: "flex",
              alignSelf: "flex-end",
              marginTop: "3%",
              marginRight: "3%",
            }}
          >
            <EditSvgWithFunc />
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            width: "95%",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Order ID"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    orderHistoryDetails?.order_id
                  )
                }
              />
              <UserInfoField
                title="Product"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    orderHistoryDetails?.name
                  )
                }
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Provider"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : orderHistoryDetails?.provider_id === 1 ? (
                    "SAM"
                  ) : orderHistoryDetails?.provider_id === 2 ? (
                    "BTC"
                  ) : (
                    ""
                  )
                }
              />
              <UserInfoField
                title="Storage Location"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    orderHistoryDetails?.location
                  )
                }
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="weight"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    orderHistoryDetails?.weight
                  )
                }
              />
              <UserInfoField
                title="Quantity"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    orderHistoryDetails?.quantity
                  )
                }
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Total weight"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    orderHistoryDetails?.weight * orderHistoryDetails?.quantity
                  )
                }
              />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "85%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#404040",
                  }}
                >
                  Product Status :
                </Typography>
                {isFetching ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  <div
                    className={`
                                border-l-2 px-2   
                                text-sm
                                 ${
                                   orderHistoryDetails?.availability === 1
                                     ? "text-[#28A745] border-[#28A745]"
                                     : "text-[#DC3545] border-[#DC3545]"
                                 } `}
                  >
                    {orderHistoryDetails?.availability === 1
                      ? "In Stock"
                      : "Out of Stock"}
                  </div>
                )}
              </Box>
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <UserInfoField
                title="Order date"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    formDate(orderHistoryDetails?.created)
                  )
                }
              />
              {orderHistoryDetails?.modified && (
                <UserInfoField
                  title="Last update"
                  data={
                    isFetching ? (
                      <Skeleton sx={{ width: 200 }} />
                    ) : (
                      formDate(orderHistoryDetails?.modified)
                    )
                  }
                />
              )}
            </Box>
            <>
              <Divider sx={{ backgroundColor: "#E6E6E6" }} />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                {orderHistoryDetails?.ingot_price && (
                  <UserInfoField
                    title="Ingot Price"
                    data={
                      isFetching ? (
                        <Skeleton sx={{ width: 200 }} />
                      ) : (
                        orderHistoryDetails?.ingot_price
                      )
                    }
                  />
                )}
                {orderHistoryDetails?.ingot_manufacturing && (
                  <UserInfoField
                    title="Manufacturing Fee"
                    data={
                      isFetching ? (
                        <Skeleton sx={{ width: 200 }} />
                      ) : (
                        orderHistoryDetails?.ingot_manufacturing
                      )
                    }
                  />
                )}
              </Box>
            </>

            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "85%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 500,
                  }}
                >
                  Order status :
                </Typography>
                {isFetching ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  <div
                    className={`
                              border-l-2 px-2   
                              text-sm
                               ${
                                 orderHistoryDetails?.status === 0
                                   ? "text-[#17A2B8] border-[#17A2B8]"
                                   : orderHistoryDetails?.status === 1
                                   ? "text-[#28A745] border-[#28A745]"
                                   : "text-[#DC3545] border-[#DC3545]"
                               } `}
                  >
                    {orderHistoryDetails?.status === 0
                      ? "Processing"
                      : orderHistoryDetails?.status === 1
                      ? "Delivered"
                      : "Canceled"}
                  </div>
                )}
              </Box>
              {orderHistoryDetails?.totalOrder && (
                <UserInfoField
                  title="Total Order"
                  data={
                    isFetching ? (
                      <Skeleton sx={{ width: 200 }} />
                    ) : (
                      orderHistoryDetails?.totalOrder
                    )
                  }
                />
              )}
            </Box>
            {orderHistoryDetails?.status === 0 && (
              <div className="grid grid-cols-1  items-center justify-center md:flex md:self-end md:gap-4">
                <Button
                  onClick={() => setOpenDelivered(true)}
                  sx={{
                    "&:hover": { backgroundColor: "#917244" },
                    backgroundColor: "#917244",
                    width: width > 768 ? "200px" : "90%",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#ffffff",
                    fontSize: "14px",
                    marginTop: "5%",
                  }}
                >
                  Mark order as Delivered
                </Button>
                <Button
                  onClick={() => setOpenCancelDialog(true)}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#ffffff",
                    },
                    backgroundColor: "#ffffff",
                    width: width > 768 ? "200px" : "90%",
                    height: "46px",
                    borderRadius: "12px",
                    color: "#917244",
                    border: "2px solid #917244",
                    fontSize: "14px",
                    marginTop: "5%",
                  }}
                >
                  Cancel Order
                </Button>
              </div>
            )}
          </Box>
        </Box>
      </Box>
      <Box
        m="20px"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          background: "#FFFFFF",
          alignItems: "center",
          borderRadius: "24px",
          height: "auto",
        }}
      >
        <Typography
          sx={{
            alignSelf: "flex-start",
            color: "#333333",
            fontSize: "18px",
            fontWeight: 400,
            marginLeft: "2%",
            marginTop: "2%",
          }}
        >
          Order Serials
        </Typography>

        <Box
          sx={{
            width: "100%",
            height: "auto",
            background: "#FFF",
            borderRadius: "12px",
          }}
        >
          <div className="flex items-center rounded-xl bg-[#f5f5f5] p-2 mb-4 w-[95%] md:w-3/5">
            <IconButton type="button" aria-label="search">
              <img alt="search-normal" src={`../../assets/search-normal.png`} />
            </IconButton>
            <InputBase
              onChange={handleSearchInput}
              sx={{ ml: 1, flex: 1 }}
              placeholder="Search using Serial number"
            />
            <OrderSerialsFilterDrawer
              data={queryParameters}
              setData={setQueryParameters}
            />
          </div>
          {/* {requestSerials.length !== 0 && ( */}
          <NewTable
            columns={requestsColumns}
            isLoading={isFetching}
            data={requestSerials}
            totalPages={totalPages}
            totalItems={totalITems}
            handlePageChange={(newPage) =>
              handlePageChange(newPage, queryParameters, setQueryParameters)
            }
            currentPage={queryParameters}
            onCellClick={handleViewRequestDetails}
          />
          {/* )} */}
          <Snackbar
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            open={open}
            autoHideDuration={2000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity="success"
              variant="filled"
              sx={{ width: "100%" }}
            >
              Status Changed Successfully
            </Alert>
          </Snackbar>
        </Box>
      </Box>
      <ReusableSnackbar />
    </Box>
  );
};

export default OrderDetails;
