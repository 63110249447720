import { Box, Grid, Typography } from "@mui/material";
import OverViewMainCard from "../../components/OverViewMainCard";
import {
  useFetchPayments,
  useFetchPaymentsWithdraws,
} from "../../services/apis/overview";
import LoadingSpinner from "../../components/LoadingSpinner";
import PaymentCard from "../../components/PaymentCard";

const PaymentTabPanel = () => {
  const today = new Date();
  const date14DaysAgo = new Date();
  date14DaysAgo.setDate(today.getDate() - 14);
  const { data: paymentData, isFetching } = useFetchPayments();
  const { data: paymentWithdrawData, isFetching: isFetchingWithdraws } =
    useFetchPaymentsWithdraws();
  return (
    <div className="h-full">
      <Box>
        <div className="text-[#404040] text-base font-medium">OverView</div>
      </Box>
      <Grid container spacing={1.5} pt={1}>
        {/* Main Cards Start  */}
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Money"
            totalMoney={
              isFetchingWithdraws ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                Number(paymentWithdrawData?.wallet).toLocaleString("en-US")
              )
            }
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Revenue"
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.revenue?.total_revenue?.toLocaleString()
              )
            }
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total withdrawals (in case all current users sold their gold)"
            totalMoney={
              isFetchingWithdraws ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                Number(paymentWithdrawData?.gold)?.toLocaleString("en-US")
              )
            }
            egp={"EGP"}
          />
        </Grid>
      </Grid>

      <div className="text-[#404040] text-base font-medium">
        Deposit Requests
      </div>
      <Grid container spacing={1.5} pt={1}>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Pending Deposit"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.pending?.deposit?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.pending?.deposit?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Approved Deposit"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.approved?.deposit?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.approved?.deposit?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Rejected Deposit"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.rejected?.deposit?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.rejected?.deposit?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
      </Grid>
      <div className="text-[#404040] text-base font-medium">
        Withdraw Requests
      </div>
      <Grid container spacing={1.5} pt={1}>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Pending Withdraw"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.pending?.withdraw?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.pending?.withdraw?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Approved Withdraw"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.approved?.withdraw?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.approved?.withdraw?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
        <Grid item lg={4} xs={12} sm={12} md={4} p={1}>
          <PaymentCard
            backgroundColor="#FFFFFF"
            title="Total Rejected Withdraw"
            totalRequests={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.rejected?.withdraw?.count?.toLocaleString()
              )
            }
            totalMoney={
              isFetching ? (
                <tr className="animate-pulse ">
                  <td className=" py-4 whitespace-nowrap ">
                    <div className="h-4 bg-gray-200 rounded w-20"> </div>
                  </td>
                </tr>
              ) : (
                paymentData?.rejected?.withdraw?.amount?.toLocaleString()
              )
            }
            value={"Request"}
            egp={"EGP"}
          />
        </Grid>
      </Grid>
      {/* End Main Cards  */}
    </div>
  );
};
export default PaymentTabPanel;
