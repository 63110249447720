import React from "react";
import useUserFilterData from "../../../zustand/useUserFilterData";

const OrderStatusType = () => {
  const { orderStatus, setOrderStatus } = useUserFilterData();
  const filterList = [
    { id: "All", name: "All", value: "All" },
    { id: "Processing", name: "Processing", value: "0" },
    { id: "Delivered", name: "Delivered", value: "1" },
    { id: "Canceled ", name: "Canceled", value: "2" },
  ];

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setOrderStatus([...orderStatus, value]);
    } else {
      setOrderStatus(orderStatus.filter((status) => status !== value));
    }
  };

  return (
    <div className="mt-4">
      <p className="text-[#404040] font-medium font-ibmArabic text-lg mb-4">
        Order Status
      </p>
      <div className="grid grid-cols-2 gap-4 whitespace-nowrap">
        {filterList.map((item) => (
          <div key={item.id} className="flex items-center gap-2">
            <input
              className="text-[#595959] size-4 cursor-pointer"
              type="checkbox"
              id={item.id}
              name="documentStatus"
              value={item.value}
              checked={orderStatus.includes(item.value)}
              onChange={handleCheckboxChange}
            />
            <label
              htmlFor={item.id} // Fixed to 'htmlFor' for JSX
              className="text-[#595959] font-medium font-ibmArabic text-lg"
            >
              {item.name}
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderStatusType;
