import { useState } from "react";
import { Box, InputBase, Paper } from "@mui/material";
import { useFetchSegmented } from "../../services/apis/notifications";
import DashboardMainHeader from "../../components/DashboardMainHeader";
import Header from "../../components/Header";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DataGridStyle } from "../../styles";
import { handlePageChange } from "../../services/utils/filters";
import AddButton from "../../components/common/AddButton";
import { SecondBox } from "../../components/common/CustomBox";
import { ItemsColumns } from "../../components/common/Columns";
import NewTable from "../../components/common/NewTable";
const Notifications = () => {
  const [searchParams, setSearchParams] = useSearchParams(); // Hook to manage URL query parameters
  const [queryParameters, setQueryParameters] = useState({
    page: Number(searchParams.get("page")) || 0,
  });
  const { data, isFetching } = useFetchSegmented(queryParameters);
  const navigate = useNavigate();
  const handleAddNotification = () => {
    navigate(`/AddNewNotification`);
  };
  const handleClick = (params) => {
    const notification = params.row.id;
    const selectedSegment = data?.result?.find(
      (segmented) => segmented?.id === notification
    );
    navigate(`/notifications/${notification}`, {
      state: { selectedSegment: selectedSegment },
    });
  };
  const notificationsColumns = ItemsColumns([
    {
      field: "title",
      specialRender: "customRender",
      renderFunction: (params) => {
        return params?.row?.title?.en;
      },
    },
    {
      field: "message",
      specialRender: "customRender",
      renderFunction: (params) => {
        return params?.row?.message?.en;
      },
    },
    { field: "created", headerName: "Date", date: true, flex: 2 },
    // {
    //   field: "orderDetails",
    //   headerName: "Actions",
    //   actions: {
    //     view: (params) => handleClick(params),
    //   },
    // },
  ]);

  return (
    <Box style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
      <Box sx={{ ml: "20px", mb: "30px" }}>
        <DashboardMainHeader />
      </Box>

      <Header title="Notification" marginL={"20px"} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          width: "100%",
          gap: 2,
          pr: 2,
        }}
      >
        <AddButton
          title={"+ New Notification"}
          onClick={() => {
            handleAddNotification();
          }}
        />
      </Box>
      <SecondBox>
        <Box m="40px 0 0 0" height="100%" sx={DataGridStyle}>
          <Box sx={{ display: "flex", width: "50%", gap: 2, mb: 2, p: 2 }}>
            <Paper
              component="form"
              sx={{
                p: "2px 4px",
                display: "flex",
                alignItems: "center",
                width: "600px",
                height: "48px",
                backgroundColor: "#F5F5F5",
                boxShadow: "none",
                borderRadius: "12px",
              }}
            >
              <InputBase
                // onChange={handleSearchInput}
                sx={{ ml: 1, flex: 1 }}
                placeholder="Filter by Status or Date ....."
              />
            </Paper>
          </Box>
          <NewTable
            columns={notificationsColumns}
            isLoading={isFetching}
            data={data?.result}
            totalItems={data?.totalItems}
            totalPages={data?.totalPages}
            handlePageChange={(newPage) =>
              handlePageChange(
                newPage,
                queryParameters,
                setQueryParameters,
                setSearchParams
              )
            }
            onCellClick={(params) => {
              handleClick(params);
            }}
            currentPage={queryParameters}
          />
        </Box>
      </SecondBox>
    </Box>
  );
};

export default Notifications;
