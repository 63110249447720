import React from "react";
import { Typography, Box, Divider, Skeleton } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import UserInfoField from "../../user-profile/UserInfoField";
import DashboardMainHeader from "../../../components/DashboardMainHeader";
import arrowLeft from "../../../svg/arrow-circle-left.svg";
import { DataGrid } from "@mui/x-data-grid";
import { DataGridStyle } from "../../../styles";
import { useSerialDetails } from "../../../services/apis/inventory";
import { formDate } from "../../../services/helpers";
import ReusableSnackbar from "../../../components/ReusableSnackbar";
import { ItemsColumns } from "../../../components/common/Columns";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { CustomLoadingOverlay } from "../../../components/common/CustomLoadingOverlay";

const SerialDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { data: serialDetails, isFetching } = useSerialDetails(id);
  const requestsColumns = ItemsColumns([
    { field: "serial", headerName: "Serial Number" },
    {
      field: "amount",
      headerName: "Weight",
      specialRender: "customRender",
      renderFunction: (params) => {
        return parseFloat(params.row.amount);
      },
    },
    {
      field: "type",
      headerName: "Type",
      specialRender: "customRender",
      renderFunction: (params) => {
        return params.row.amount >= 0 ? "Buy" : "Sell";
      },
    },

    { field: "created", headerName: "Created At", date: true },
  ]);

  if (isFetching) return <LoadingSpinner />;
  return (
    <Box
      style={{
        width: "100%",
      }}
    >
      <DashboardMainHeader />
      <Box
        sx={{
          margin: "0 0 1% 2%",
          display: "flex",
          gap: "5px",
          alignItems: "center",
        }}
      >
        <Box sx={{ cursor: "pointer" }}>
          <img
            src={arrowLeft}
            alt=""
            onClick={() => {
              navigate(-1);
            }}
          />
        </Box>
        <Typography component="span" fontSize={"24px"} fontWeight={"400"}>
          {serialDetails?.serial} Ingots Details
        </Typography>
      </Box>
      <Box
        m="20px"
        sx={{
          display: "flex",
          width: "95%",
          flexDirection: "column",
          gap: 2,
          background: "#FFFFFF",
          alignItems: "center",
          borderRadius: "24px",
          height: "auto",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "95%",
            marginTop: "3%",
            marginBottom: "3%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              width: "100%",
              flexDirection: "column",
              gap: 3,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              {serialDetails?.serial && (
                <UserInfoField
                  title="Ingot Original Serial"
                  data={serialDetails?.serial}
                />
              )}
              {/* <UserInfoField title="Order ID" data={serialDetails?.order_id} /> */}
              <UserInfoField title="Product" data={serialDetails?.name} />
              {/* <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "85%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#404040",
                  }}
                >
                  Product Status :
                </Typography>
                {isFetching ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  <div
                    className={`
                                              border-l-2 px-2   
                                              text-sm
                                               ${
                                                 serialDetails?.productStatus ===
                                                 1
                                                   ? "text-[#28A745] border-[#28A745]"
                                                   : "text-[#DC3545] border-[#DC3545]"
                                               } `}
                  >
                    {serialDetails?.productStatus === 1
                      ? "In Stock"
                      : "Out of Stock"}
                  </div>
                )}
              </Box> */}
              {/* <UserInfoField
                title="Product Status"
                data={
                  serialDetails?.productStatus === 1
                    ? "In Stock"
                    : "Out of Stock"
                }
              /> */}
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Provider"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : serialDetails?.provider_id === 1 ? (
                    "SAM"
                  ) : serialDetails?.provider_id === 2 ? (
                    "BTC"
                  ) : (
                    ""
                  )
                }
              />
              <UserInfoField
                title="Storage Location"
                data={
                  isFetching ? (
                    <Skeleton sx={{ width: 200 }} />
                  ) : (
                    serialDetails?.location
                  )
                }
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField title="weight" data={serialDetails?.name} />
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "85%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#404040",
                  }}
                >
                  Product Status :
                </Typography>
                {isFetching ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  <div
                    className={`
                                              border-l-2 px-2   
                                              text-sm
                                               ${
                                                 serialDetails?.productStatus ===
                                                 1
                                                   ? "text-[#28A745] border-[#28A745]"
                                                   : "text-[#DC3545] border-[#DC3545]"
                                               } `}
                  >
                    {serialDetails?.productStatus === 1
                      ? "In Stock"
                      : "Out of Stock"}
                  </div>
                )}
              </Box>
              {/* <UserInfoField
                title="Available weight "
                data={serialDetails?.availableWeight}
              />
              <UserInfoField
                title="Sold weight"
                data={parseFloat(serialDetails?.sold_weight)}
              /> */}
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Available weight "
                data={serialDetails?.availableWeight + " Gram"}
              />
              <UserInfoField
                title="Sold weight"
                data={parseFloat(serialDetails?.sold_weight) + " Gram"}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <UserInfoField
                title="Ingot Price"
                data={serialDetails?.ingot_price + " EGP"}
              />
              <UserInfoField
                title="Manufacturing Fee"
                data={parseFloat(serialDetails?.ingot_manufacturing) + " EGP"}
              />
            </Box>
            <Divider sx={{ backgroundColor: "#E6E6E6" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                flexDirection: {
                  xs: "column",
                  sm: "row",
                },
                gap: { xs: 2, sm: 3 },
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  gap: 1,
                  width: "85%",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Lato",
                    fontSize: "14px",
                    fontStyle: "normal",
                    fontWeight: 400,
                    color: "#404040",
                  }}
                >
                  Ingot status :
                </Typography>
                {isFetching ? (
                  <Skeleton sx={{ width: 200 }} />
                ) : (
                  <div
                    className={`
                                              border-l-2 px-2   
                                              text-sm
                                               ${
                                                 serialDetails?.ingotStatus ===
                                                 1
                                                   ? "text-[#28A745] border-[#28A745]"
                                                   : "text-[#DC3545] border-[#DC3545]"
                                               } `}
                  >
                    {serialDetails?.ingotStatus === 1
                      ? "In Stock"
                      : "Out of Stock"}
                  </div>
                )}
              </Box>
              {serialDetails?.modified && (
                <UserInfoField
                  title="Last update"
                  data={formDate(serialDetails?.modified)}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {serialDetails?.serials?.length !== 0 && (
        <Box
          m="20px"
          sx={{
            display: "flex",
            width: "95%",
            flexDirection: "column",
            gap: 2,
            background: "#FFFFFF",
            alignItems: "center",
            borderRadius: "24px",
            height: "auto",
          }}
        >
          <Typography
            sx={{
              alignSelf: "flex-start",
              color: "#333333",
              fontSize: "18px",
              fontWeight: 400,
              marginLeft: "2%",
              marginTop: "2%",
            }}
          >
            Serials
          </Typography>
          <Box
            sx={{
              width: "100%",
              height: "auto",
              background: "#FFF",
              borderRadius: "12px",
            }}
          >
            <Box
              m="40px 0 0 0"
              height="auto"
              sx={[
                DataGridStyle,
                {
                  overflowX: "auto",
                  "& .MuiDataGrid-columnHeader:first-of-type": {
                    backgroundColor: "#ffffff",
                    borderBottom: "none",
                    pointerEvents: "auto",
                  },
                },
              ]}
            >
              <DataGrid
                components={{
                  LoadingOverlay: () => CustomLoadingOverlay({ columns: 5 }),
                }}
                pageSize={10}
                disableColumnMenu={true}
                rows={isFetching ? [] : serialDetails?.serials}
                loading={isFetching}
                rowsPerPageOptions={[10]}
                columns={requestsColumns}
                sx={{
                  backgroundColor: "#ffffff",
                  height: "650px",
                  minWidth: "1000px",
                }}
              />
            </Box>
          </Box>
        </Box>
      )}
      <ReusableSnackbar />
    </Box>
  );
};

export default SerialDetails;
